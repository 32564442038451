<template>
  <div class="container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-row justify="center">
      <template>
        <v-card width="1000">
          <v-form ref="form" @submit="updateModuleData" method="put">
            <v-container class="px-50 v-card-padding" fluid>
              <v-subheader style="font-size:15px;">Basic Info</v-subheader>
              <v-divider></v-divider>
              <v-card class="mx-auto pa-5" max-width="1000" outlined>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="updateModule.moduleName"
                      label="Module Name"
                      required
                      :rules="nameRules"
                    ></v-text-field>
                    <div style="color: red">
                      {{ upmessage1 }}
                    </div>
                  </v-col>
                  <v-col col="4">
                    <v-text-field
                      v-model="updateModule.url_slug"
                      label="Url Slug"
                      :rules="urlRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="updateModule.module_icon"
                      label="Module Icon"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      :items="panel_data"
                      label="Select Panel"
                      v-model="updateModule.panel_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="parent_id"
                      label="Select Parent"
                      v-model="updateModule.parent_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="module_sort"
                      label="Select Sort"
                      v-model="updateModule.module_sort"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="4" v-if="updateModule.panel_id == 1">
                    <v-select
                      :items="internal_module_data"
                      label="Internal Module"
                      v-model="updateModule.internal_module"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col :cols="12">
                  <v-btn
                color="blue darken-1"
                class="text-right mb-4"
                small
                @click="addEditOperationsModal=true"
              >
                Add Operation
              </v-btn>
                </v-col>
              </v-row>
              <v-simple-table
                dense
                v-if="updateModule.operation_id.length"
              >
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-left">
                        Operation
                      </th>
                      <th class="text-left">
                        Total Endpoints Mapped
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in updateModule.operation_id"
                      :key="item.operation_id"
                    >
                      <td>{{ get_operation_name(item.operation_id) }}</td>
                      <td>
                        {{ getLengthEndpointsMapped(item.sem_ids) }}
                      </td>
                      <td>
                        <v-icon
                          medium
                          color="grey"
                          title="View Mapped Endpoints"
                          @click="
                            showOperationMappedEndpoints(
                              item.operation_id,
                              item.sem_ids
                            )
                          "
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          medium
                          color="grey"
                          class="ml-3"
                          title="Edit Operation"
                          @click="
                            addEndpointsToEdit(item.operation_id, item.sem_ids)
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          medium
                          color="red"
                          class="ml-3"
                          title="Remove Operation"
                          @click="removeOperation(item.operation_id)"
                        >
                          mdi-close
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                @click="updateModuleData"
                type="submit"
                v-if="editMode"
              >
                Update
              </v-btn>
              <v-btn
                v-else
                color="blue darken-1"
                @click="addModuleData"
                type="submit"
              >
                Add
              </v-btn>
              <v-btn class="close_button" @click="redirectToManageModule">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-row>
    <v-dialog v-model="addEditOperationsModal" max-width="900">
      <v-card>
        <v-row class="ma-1">
          <v-col cols="11" class="text-h3">
            <p v-if="editEndpointsMode">Update Operation</p>
            <p v-else>Add Operation</p>
          </v-col>
          <v-col cols="1" class="text-h3">
            <v-icon
              medium
              color="grey"
              class="ml-5"
              title="Close"
              @click="closeAddEditOperationModal"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <!-- <v-subheader style="font-size:15px;margin-top:15px;"
                >Add/Edit Operation & Service Mapping</v-subheader
              >
              <v-divider></v-divider> -->
              <v-card class="mx-auto pa-5" max-width="1000" outlined>
                <v-row>
                  <v-col cols="4" style="padding:0px 15px 0px 15px;">
                    <v-select
                      :items="operation"
                      :disabled="editEndpointsMode"
                      label="Select Operation"
                      v-model="temp_operation_endpoint_obj.operation_id"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="4" style="padding:0px 15px 0px 15px;">
                    <!-- v-if="temp_operation_endpoint_obj.operation_id" -->
                  <!-- > -->
                    <v-select
                      :items="services_dropdown"
                      label="Select Service"
                      v-model="temp_operation_endpoint_obj.service_id"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="8" style="padding:0px 15px 0px 15px;">
                    <!-- v-if="temp_operation_endpoint_obj.operation_id" -->
                  <!-- > -->
                    <v-text-field
                      prepend-icon="mdi-search-web"
                      label="Search Endpoint Name or Route"
                      v-model="search_endpoint_text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-simple-table
                  dense>
                  <!-- v-if="temp_operation_endpoint_obj.operation_id" -->
                <!-- > -->
                  <template v-slot:default>
                    <thead class="v-data-table-header">
                      <tr>
                        <th class="text-left">
                          Select
                        </th>
                        <th class="text-left">
                          Route
                        </th>
                        <th class="text-left">
                          Http Verb
                        </th>
                        <th class="text-left">
                          Service Name
                        </th>
                        <th class="text-left">
                          Description
                        </th>
                        <th class="text-left">
                          Open API
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in endpoints" :key="item.sem_id">
                        <td>
                          <input
                            type="checkbox"
                            :id="item.sem_id"
                            :value="item.sem_id"
                            :checked="
                              temp_operation_endpoint_obj.sem_ids.includes(
                                item.sem_id
                              )
                            "
                            @click="
                              toggleEndppointCheckbox($event, item.sem_id)
                            "
                          />
                        </td>
                        <td>{{ item.route }}</td>
                        <td>{{ item.http_method }}</td>
                        <td>{{ item.service_name }}</td>
                        <td>{{ item.endpoint_desc }}</td>
                        <td>{{ item.api_specs }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    color="blue darken-1"
                    v-if="editEndpointsMode"
                    @click="updateModuleOperationEndpointsMapping"
                  >
                    Update Operation
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="blue darken-1"
                    v-else
                    @click="addModuleOperationEndpointsMapping"
                  >
                    Add Operation
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="operationMappedEndpointsModal" max-width="700">
      <v-card>
        <v-row class="ma-5">
          <v-col cols="11" class="text-h3">
            Operation {{ operation_mapped_endpoints_modal_heading }} : Mapped
            Endpoints
          </v-col>
          <v-col cols="1" class="text-h3">
            <v-icon
              medium
              color="grey"
              class="ml-5"
              title="Close"
              @click="operationMappedEndpointsModal = false"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-simple-table
          dense
          v-if="operation_mapped_endpoints_modal_data.length"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-left">
                  Route
                </th>
                <th class="text-left">
                  Service Name
                </th>
                <th class="text-left">
                  Description
                </th>
                <th class="text-left">
                  Http Verb
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in operation_mapped_endpoints_modal_data"
                :key="index"
              >
                <td>{{ item.endpoint_name }}</td>
                <td>{{ item.service_name }}</td>
                <td>{{ item.endpoint_desc }}</td>
                <td>{{ item.http_methods }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Index, Buttons, Loading, Pagination },
  name: "AddModule",
  data() {
    return {
      operation_mapped_endpoints_modal_data: [],
      operation_mapped_endpoints_modal_heading: "",
      operationMappedEndpointsModal: false,
      addEditOperationsModal:false,
      valid: true,
      checkbox: false,
      operation: [],
      services: [],
      services_dropdown: [],
      search_endpoint_text: "",
      temp_operation_endpoint_obj: {
        operation_id: null,
        service_id: null,
        sem_ids: []
      },
      modules: [],
      module_data: [],
      isLoading: true,
      fullPage: true,
      deleteItems: [],
      checked_moduleId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      endpoint_list: [],

      updateModule: {
        moduleId: 0,
        moduleName: null,
        operation_id: [],
        module_icon: null,
        module_sort: null,
        url_slug: null,
        parent_id: null,
        panel_id: null,
        internal_module: 0
      },
      checked: false,
      nameRules: [v => !!v || "Name is required"],
      urlRules: [v => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      editMode: false,
      /////
      editEndpointsMode: false,
      internal_module_data: [{text:'Yes',value:1},{text:'No',value:0}]
      ///
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.updateModule.moduleId = this.$route.params.id;
      this.isLoading = false;
      this.editMode = true;
    } else {
      this.isLoading = false;
    }
    this.getModule(1);
    this.getEndpoints()
    // this.getallServiceEndpoints();
  },
  computed: {
    //pagination computed methods
    endpoints: function() {
      let endpoints = [];
      if (this.temp_operation_endpoint_obj.service_id != null || this.search_endpoint_text != "") {
          if (this.services.length) {
                this.services.forEach(s => {
                  // console.log("dd id", this.temp_operation_endpoint_obj.service_id)
                  // console.log("dd operation_id", this.temp_operation_endpoint_obj.operation_id)
                    if((this.temp_operation_endpoint_obj.service_id != null) && (this.search_endpoint_text != "" )){
                      if((s.service_id == this.temp_operation_endpoint_obj.service_id) && (s.name.toLowerCase().includes(this.search_endpoint_text.toLowerCase()))){
                        let row = {
                          id: s.id,
                          sem_id: s.sem_id,
                          endpoint_desc: s.desc,
                          service_name: s.service_name,
                          route: s.name,
                          http_method: s.http_methods,
                          api_specs: "-"
                        };
                        endpoints.push(row);
                      }
                      }else if((this.temp_operation_endpoint_obj.service_id != null) && (this.search_endpoint_text == "" )){
                        if((s.service_id == this.temp_operation_endpoint_obj.service_id)){
                          let row = {
                          id: s.id,
                          sem_id: s.sem_id,
                          endpoint_desc: s.desc,
                          service_name: s.service_name,
                          route: s.name,
                          http_method: s.http_methods,
                          api_specs: "-"
                        };
                        endpoints.push(row);
                        }
                      }
                      else if((this.temp_operation_endpoint_obj.service_id == null) && (this.search_endpoint_text != "" )){
                        if((s.name.toLowerCase().includes(this.search_endpoint_text.toLowerCase()))){
                          let row = {
                          id: s.id,
                          sem_id: s.sem_id,
                          endpoint_desc: s.desc,
                          service_name: s.service_name,
                          route: s.name,
                          http_method: s.http_methods,
                          api_specs: "-"
                        };
                        endpoints.push(row);
                        }
                      }
                  })
            }
        }else if(this.services.length) {
                  this.services.forEach(s => {
                    let row = {
                      id: s.id,
                      sem_id: s.sem_id,
                      endpoint_desc: s.desc,
                      service_name: s.service_name,
                      route: s.name,
                      http_method: s.http_methods,
                      api_specs: "-"
                    };
                    endpoints.push(row);
                  })
          }
          let uniqueEndpoints = [...new Set(endpoints.map(item => item))];
      return uniqueEndpoints;
  }
  },
  methods: {
    closeAddEditOperationModal(){
       this.temp_operation_endpoint_obj.service_id = null;
      this.temp_operation_endpoint_obj.operation_id = null;
      this.temp_operation_endpoint_obj.sem_ids = [];
      this.search_endpoint_text = "";
      this.editEndpointsMode = false;
      this.addEditOperationsModal = false
    },
    getEndpoints(m_id = "") {
      let view = this;
      const path =
      // this.$url("MANAGE_ENDPOINT") + "?m_id=" + view.updateModule.moduleId;
      this.$url("MANAGE_ENDPOINT") + "?show_all=True";
      this.isLoading = true;
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          console.log(res);
          this.endpoint_list = res.data.payload.endpoints;
          this.services = [];
          if (Array.isArray(this.endpoint_list) && this.endpoint_list.length) {
            for (let i = 0; i < this.endpoint_list.length; i++) {
              let http_method_list = ""

              for (let j = 0; j < this.endpoint_list[i].http_methods.length; j++) {
                if(j!=0){
                    http_method_list = http_method_list + ", "
                }
                http_method_list = http_method_list + this.endpoint_list[i].http_methods[j].method_name
              }

              // let operation_obj = []
              // let op_ids = []
              // for (let j = 0; j < this.endpoint_list[i].module_operations.length; j++) {
              //     for (let k = 0; k < this.endpoint_list[i].module_operations[j].operation_ids.length; k++) {
              //         let row = {}
              //         let sem_id = []
              //         op_ids[k] =  this.endpoint_list[i].module_operations[j].operation_ids[k]
              //         sem_id.push(this.endpoint_list[i].sem_id)
              //         row['operation_id'] = this.endpoint_list[i].module_operations[j].operation_ids[k]
              //         row['sem_ids'] = sem_id
              //         operation_obj.push(row)
              //     }
              // }
              // this.updateModule.operation_id = operation_obj
              let tempobj = {
                id: this.endpoint_list[i].endpoint_id,
                name: this.endpoint_list[i].endpoint_name,
                desc: this.endpoint_list[i].endpoint_desc,
                panel_name: this.endpoint_list[i].panel_name,
                service_id: this.endpoint_list[i].service_id,
                service_name: this.endpoint_list[i].service_name,
                http_methods: http_method_list,
                sem_id: this.endpoint_list[i].sem_id,
                operation_ids: this.endpoint_list[i].module_operations.length ? this.endpoint_list[i].module_operations[0].operation_ids : []
              };
              this.services.push(tempobj);
            }
          }
          console.log(this.services);
          //fetching services
          this.get_services();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

  get_services() {
      const path = this.$url("MANAGE_SERVICES");
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          console.log(res);
          this.serviceName = res.data.payload.operation[0];
          this.total = this.serviceName.length;
          if (Array.isArray(this.serviceName) && this.serviceName.length) {
            this.services_dropdown = [];
            let i;
              this.services_dropdown.push({text:'Select',value:null})
            for (i = 0; i < this.serviceName.length; i++) {
              let tempobj = {
                value: this.serviceName[i].service_id,
                text: this.serviceName[i].name,
              };

              this.services_dropdown.push(tempobj);
            }
          }
        });
    },

    addEndpointsToEdit(operation_id, sem_ids) {
      this.editEndpointsMode = true;
      let new_temp_sem_ids = sem_ids;
      this.temp_operation_endpoint_obj.operation_id = operation_id;
      this.temp_operation_endpoint_obj.sem_ids = new_temp_sem_ids;
      this.addEditOperationsModal = true
    },
    updateModuleOperationEndpointsMapping() {
      this.updateModule.operation_id.forEach(o => {
        if (o.operation_id == this.temp_operation_endpoint_obj.operation_id) {
          o.sem_ids = [];
          o.sem_ids = this.temp_operation_endpoint_obj.sem_ids;
        }
      });
      this.closeAddEditOperationModal()
    },
    removeOperation(operation_id) {
      let view = this;
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will remove the operation",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, remove it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false
        })
        .then(result => {
          if (result.isConfirmed) {
            let operations = this.updateModule.operation_id;
            let new_operations = operations.filter(o => {
              return o.operation_id !== operation_id;
            });
            console.log(new_operations);
            this.updateModule.operation_id = new_operations;
          }
        });
    },
    getLengthEndpointsMapped(sem_ids) {
      let endpoints_mapped = [];
      if (this.services.length) {
          this.services.forEach(s => {
                if (sem_ids.includes(s.sem_id)) {
                  endpoints_mapped.push(1);
                }
          });
      }
      return endpoints_mapped.length
    },
    showOperationMappedEndpoints(operation_id, sem_ids) {
      let endpoints_mapped = [];
      this.operation_mapped_endpoints_modal_data = [];
      this.operation_mapped_endpoints_modal_heading = this.get_operation_name(
        operation_id
      );
      if (this.services.length) {
          this.services.forEach(s => {
            // if (s.children.length) {
              // s.children.forEach(c => {
                if (sem_ids.includes(s.sem_id)) {
                  let obj = {
                    service_name: s.service_name,
                    endpoint_name: s.name,
                    endpoint_desc: s.desc,
                    http_methods: s.http_methods
                  };
                  endpoints_mapped.push(obj);
                }
              // });
          });
      }
      this.operation_mapped_endpoints_modal_data = endpoints_mapped;
      this.operationMappedEndpointsModal = true;
    },
    get_operation_name(operation_id) {
      let name = "";
      if (this.operation.length) {
        this.operation.forEach(o => {
          if (o.value == operation_id) {
            name = o.text;
          }
        });
      }
      return name;
    },
    addModuleOperationEndpointsMapping() {
      if (this.temp_operation_endpoint_obj.operation_id != null) {
        let new_obj = {
          operation_id: this.temp_operation_endpoint_obj.operation_id,
          sem_ids: this.temp_operation_endpoint_obj.sem_ids
        };
        if (this.updateModule.operation_id.length) {
          let operation_exists = [];
          this.updateModule.operation_id.forEach(o => {
            if (o.operation_id == this.temp_operation_endpoint_obj.operation_id) {
              operation_exists.push(1);
            }
          });
          if (operation_exists.length == 0) {
            this.updateModule.operation_id.push(new_obj);
            this.temp_operation_endpoint_obj.service_id = null;
            this.temp_operation_endpoint_obj.operation_id = null;
            this.temp_operation_endpoint_obj.sem_ids = [];
            this.search_endpoint_text = "";
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Operation is already added!"
            });
          }
        } else {
          this.updateModule.operation_id.push(new_obj);
          this.temp_operation_endpoint_obj.service_id = null;
          this.temp_operation_endpoint_obj.operation_id = null;
          this.temp_operation_endpoint_obj.sem_ids = [];
          this.search_endpoint_text = "";
        }
        this.addEditOperationsModal = false
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select operation!"
        });
      }
    },
    toggleEndppointCheckbox(e, sem_id) {
      if (e.target.checked) {
        if (!this.temp_operation_endpoint_obj.sem_ids.includes(sem_id)) {
          this.temp_operation_endpoint_obj.sem_ids.push(sem_id);
        }
      } else {
        this.temp_operation_endpoint_obj.sem_ids.splice(
          this.temp_operation_endpoint_obj.sem_ids.indexOf(sem_id),
          1
        );
      }
    },
    redirectToManageModule() {
      if (this.editMode) {
        this.$router.push({ path: "./../manage-modules" });
      } else {
        this.$router.push({ path: "/manage-modules" });
      }
    },
    getallServiceEndpoints() {
      let data = this;
      const path = data.$url("MANAGE_SERVICES")+ "?m_id="+data.updateModule.moduleId;
      data.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: data })
        .get(path)
        .then(res => {
          data.isLoading = false;
          let service_data;
          service_data = res.data.payload.operation[0];
          console.log(service_data);
          let i;
          for (i = 0; i < service_data.length; i++) {
            let tempobj = {
              name: service_data[i].name,
              service_id: service_data[i].service_id,
              children: service_data[i].children
            };
            this.services.push(tempobj);
          }
          let service_master_data = res.data.payload.operation[1];
          if (service_master_data.length) {
            data.services_dropdown = [];
            data.services_dropdown.push({ text: "", value: null });
            service_master_data.forEach(o => {
              data.services_dropdown.push({ text: o[1], value: o[0] });
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    /////
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.moduleId = null;
      this.moduleName = null;
      this.updateModule.operation_id = [];
      this.updateModule.module_icon = null;
      this.updateModule.module_sort = null;
      this.updateModule.parent_id = null;
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getModule(page = "", query = "") {
      let check = this;
      check.isLoading = true;

      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(this.$url("MODULE"), {
          params: { ids: check.updateModule.moduleId }
        })
        .then(res => {
          check.isLoading = false;
          console.log(res.data.payload.module);
          if (check.editMode) {
            if (res.data.payload.module.modules_data.length) {
              res.data.payload.module.modules_data.forEach(function(item) {
                check.updateModule.moduleName = item.module_name;
                check.updateModule.module_icon = item.module_icon;
                check.updateModule.module_sort = item.module_sort;
                check.updateModule.url_slug = item.url_slug;
                check.updateModule.panel_id = item.panel_id;
                check.updateModule.parent_id = item.parent_id;
                console.log(item.internal_module);
                check.updateModule.internal_module = item.internal_module;
              });
            }
            if (res.data.payload.module.endpoint_operation_mapping.length) {
              this.updateModule.operation_id = res.data.payload.module.endpoint_operation_mapping;
            }
          }

          // operation dropdown
          if (res.data.payload.module.operations.length) {
            check.operation.push({ text: "Select", value: null });
            res.data.payload.module.operations.forEach(function(item) {
              let a = {
                text: item.operation_name,
                value: item.operation_id
              };
              check.operation.push(a);
            });
          }

          // parent dropdown
          if (res.data.payload.module.all_modules.length) {
            check.parent_id.push({ text: "Select One", value: null });
            res.data.payload.module.all_modules.forEach(function(item) {
              if (item.parent_id == 0) {
                let a = {
                  text: item.module_name,
                  value: item.module_id
                };
                check.parent_id.push(a);
              }
            });
          }
          // module sort
          if (res.data.payload.module.all_modules.length) {
            res.data.payload.module.all_modules.forEach(function(item) {
              let a = {
                text: item.module_id,
                value: item.module_id
              };
              check.module_sort.push(a);
              check.module_sort = check.module_sort.sort((a, b) =>
                a.value > b.value ? 1 : b.value > a.value ? -1 : 0
              );
            });
          }

          // panel dropdown
          const path = this.$url("PANEL_DATA");
          this.$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: check
          })
            .get(path)
            .then(res => {
              if (res.data.payload.panelId.length) {
                res.data.payload.panelId.forEach(function(item) {
                  let a = {
                    text: item[1],
                    value: item[0]
                  };
                  check.panel_data.push(a);
                });
              }
            });
        });
    },
    addModuleData(e) {
      e.preventDefault();
      let check = this;
      console.log(this.updateModule.moduleName);
      if (
        this.updateModule.moduleName.trim() != "" &&
        this.updateModule.moduleName != null &&
        this.updateModule.url_slug.trim() != "" &&
        this.updateModule.url_slug != null
      ) {
        check.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(this.$url("MODULE"), this.updateModule)
          .then(result => {
            check.isLoading = false;
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].moduleId;
            this.message2 = result.data.payload[0].moduleName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].moduleId;
              this.dialog = true;
              if (result.data.payload[0].module) {
                check.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: result.data.payload[0].module
                });
              }
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Record(s) added successfully",
                    text: result.data.payload.message
                  })
                  .then(function() {
                    //location.href = '/manage-modules';
                    check.$router.push({ path: "manage-modules" });
                  });
              }
              //this.getModule(this.page, this.query);
              this.reset();
            }
            console.log(result);
          });
      }
    },
    updateModuleData(e) {
      e.preventDefault();
      let data = this;
      if (
        this.updateModule.moduleName.trim() != "" &&
        this.updateModule.moduleName != null &&
        this.updateModule.url_slug.trim() != "" &&
        this.updateModule.url_slug != null
      ) {
        data.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "edit", vueScope: data })
          .put(data.$url("MODULE"), data.updateModule)
          .then(result => {
            data.isLoading = false;
            this.upmessage1 = result.data.payload.moduleId;
            this.upmessage2 = result.data.payload.moduleName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.moduleId;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Record(s) updated successfully",
                    text: result.data.payload.message
                  })
                  .then(function() {
                    location.href = "/manage-modules";
                    data.$router.push({ path: "./../manage-modules" });
                  });
                view.updateModule.operation_id = [];
              }
              this.getModule(data.page, data.query);
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_moduleId.indexOf(e.target.value) == -1) {
          this.checked_moduleId.push(e.target.value);
        }
      } else {
        this.checked_moduleId.splice(
          this.checked_moduleId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_moduleId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteModule(e) {
      e.preventDefault();
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .delete(this.$url("MODULE"), {
          params: { id: this.deleteItems }
        })
        .then(response => {
          if (response.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message
            });
          }
          this.getModule(this.page, this.query);
          this.deleteItems = [];
        });
    },
    selectAll: function() {
      let module = this.modules;
      if (this.allSelected == true) {
        if (Array.isArray(module) && module.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < module.length; i++) {
            console.log(module[i]);
            this.deleteItems.push(module[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function() {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchModuleName: function() {
      this.getModule(this.page, this.query);
      console.log(this.query);
    }
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function(o) {
      if (o) {
        //this.getModule(1);
      }
    },
    page: function(ob) {
      //this.getModule(ob);
    }
  }
};
</script>
